import { format } from 'date-fns'
import { Tag } from '@applyboard/crystal-ui'

import { ApplicationDetailsIntent } from '../types'

import { getIntent } from './utils'

type ApplicationDetailCardIssuedProps = Readonly<{
  intent: ApplicationDetailsIntent
  issuedOn: Date | undefined
}>

export function ApplicationDetailCardIssued(props: ApplicationDetailCardIssuedProps) {
  if (props.issuedOn === undefined) {
    return null
  }
  return (
    <Tag intent={getIntent(props.intent)}>
      {`Issued on ${format(props.issuedOn, 'MMM dd, yyy')}`}
    </Tag>
  )
}
