import { Flex, Notice } from '@applyboard/crystal-ui'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { RawListApplicationsResponse } from '../../hooks/useListApplications'
import { ApplicationsCard } from './ApplicationsCard'
import { ApplicationsTable } from './ApplicationsTable'
import { Navigate, useLocation } from 'react-router-dom'

type ApplicationsListProps = {
  applications: RawListApplicationsResponse['data'] | null
}

enum ApplicationStateOrder {
  DOCUMENTS_REQUESTED_IN_ASSESSMENT = 0,
  DOCUMENTS_REQUESTED_IN_SCREENING = 0,
  DEFERRAL_REQUESTED_IN_SCREENING = 0,
  DEFERRAL_REQUESTED_IN_ASSESSMENT = 0,
  DEFERRAL_REQUESTED_IN_INITIAL_OFFER = 0,
  PAYMENT_PROCESSING = 1,
  INITIAL_OFFER = 2,
  READY_FOR_SCREENING = 3,
  READY_FOR_ASSESSMENT = 3,
  DRAFT = 4,
  DECLINED = 5,
}

export function ApplicationsList(props: ApplicationsListProps) {
  // Due to a delay to update OpenSearch, this object is needed to handle delete application
  const [tableApplications, setTableApplications] = useState(props.applications)
  const { state } = useLocation()
  const [deletedApplication] = useState(state)
  const [noticeMessage, setNoticeMessage] = useState<string>(() => {
    if (state?.attributes?.programSelected?.program?.name) {
      return `Your ${state.attributes?.programSelected?.program?.name} draft application has been discarded`
    }

    return ''
  })

  const sortedApplication = sortBy(
    tableApplications?.filter(app => {
      if (deletedApplication?.id) {
        return app.id !== deletedApplication.id
      }

      return true
    }),
    [
      function (app) {
        return app.attributes?.applicationState
          ? ApplicationStateOrder[
              app.attributes.applicationState as keyof typeof ApplicationStateOrder
            ]
          : 0
      },
      function (app) {
        return app.attributes?.programSelected?.program?.name
      },
      function (app) {
        return app.attributes?.programSelected?.programIntakeTerm?.name
      },
    ],
  )

  if (state) {
    return <Navigate to="/" replace />
  }

  const handleDeleteApplication = (
    application: RawListApplicationsResponse['data'][number],
  ): void => {
    setNoticeMessage(
      `Your ${application.attributes?.programSelected?.program?.name} draft application has been discarded`,
    )

    const newApplications = tableApplications?.filter(app => {
      return app.id !== application.id
    })

    if (newApplications) {
      setTableApplications(newApplications)
    } else {
      setTableApplications([])
    }
  }

  return (
    <>
      {noticeMessage ? (
        <Flex pb={10} pt={4}>
          <Notice intent="positive">
            {noticeMessage}
            <Notice.CloseButton onClick={() => setNoticeMessage('')} />
          </Notice>
        </Flex>
      ) : null}

      <ApplicationsCard applications={sortedApplication} onDelete={handleDeleteApplication} />
      <ApplicationsTable applications={sortedApplication} onDelete={handleDeleteApplication} />
    </>
  )
}
