import { DescriptionList, Flex, Tag } from '@applyboard/crystal-ui'

import { ApplicationDetailCardDescriptionItem } from './ApplicationDetailCardDescriptionItem'
import { getTagInformation } from './utils'
import { format } from 'date-fns'
import { DecisionResponseType } from 'applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

type ApplicationDetailCardDescriptionProps = Readonly<{
  status: DecisionResponseType
  statusChangedOn?: Date
}>

export function ApplicationDetailCardDescription(props: ApplicationDetailCardDescriptionProps) {
  const { label, intent } = getTagInformation(props.status)

  return (
    <DescriptionList variant="free">
      <Flex
        direction={{ xs: 'column', lg: 'column' }}
        gap={4}
        justify={{ xs: 'start', lg: 'start' }}
      >
        <ApplicationDetailCardDescriptionItem
          term="Offer Status"
          details={<Tag intent={intent}>{label}</Tag>}
        />
        {props.statusChangedOn &&
        (props.status === 'ACCEPTED' || props.status === DecisionResponseType.REJECTED) ? (
          <ApplicationDetailCardDescriptionItem
            term={props.status === 'ACCEPTED' ? 'Accepted on' : 'Rejected on'}
            details={<Tag intent="secondary">{format(props.statusChangedOn, 'MMM dd, yyy')}</Tag>}
          />
        ) : null}
      </Flex>
    </DescriptionList>
  )
}
